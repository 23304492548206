
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomeView",
  data() {
    return {
      buttonVisible: false,
    };
  },
  mounted() {
    //hack to get around tailwind not hiding element at start
    setTimeout(() => {
      this.buttonVisible = true;
    }, 600);
  },
});

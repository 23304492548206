<template>
  <router-view class="relative z-10 bg-slate-50" />
  <div class="relative h-32 z-[1]"></div>
  <div
    id="footer"
    class="py-4 bg-theme-black-1 text-theme-gold-4 flex items-center flex-col bottom-0 fixed w-full h-32 z-[5]"
  >
    <div>Contact Grux Luxury Properties LLC</div>
    <a
      href="tel:+1-425-269-8046"
      class="underline underline-offset-4 decoration-1"
    >
      +1-425-269-8046
    </a>
    <a
      href="mailto:email@email.com"
      class="underline underline-offset-4 decoration-1"
    >
      grgmoney@yahoo.com
    </a>
    <div class="flex pt-1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="24"
        viewBox="0 0 24 24"
        class="fill-theme-gold-4 mr-1"
      >
        <path
          d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"
        />
      </svg>
      Bellingham, WA
    </div>
  </div>
</template>
